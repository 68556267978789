<template>
  <div>
    <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
      <Title title="使用记录"/>
      <div class="ct_box">
        <div class="search_status_box flex">
          <div class="search_box flex">
            <div class="ai_select flex">
              <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon"/>
              <el-select @change="classChane" v-model="title" placeholder="请选择要搜索的类别">
                <el-option
                    v-for="item in search_list" :label="item.name" :key="item.id" :value="item.id">
                </el-option>
              </el-select>
              <!-- <button class="search_but" @click="get_list">搜索</button> -->
            </div>
          </div>
        </div>
        <table class="table2">
          <thead>
          <tr>
            <td>ID</td>
            <td>类别</td>
            <td>输入内容</td>
            <td>AI生成内容</td>
            <td>消费金额</td>
            <td>使用日期</td>
            <td style="width: 10rem;">操作</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in tableData" :key="item.id">
            <td>{{ (page - 1) * limit + 1 + index }}</td>
            <!--   -->
            <td>{{ search_list[item.chat_model].name }}</td>

            <td style="color: #108CDD;cursor: pointer;" @click="wordOrderShow(0, item)">
              {{ item.input_content == '' ? '-' : '查看' }}
            </td>

            <td style="color: #108CDD;cursor: pointer;" @click="wordOrderShow(1, item)">
              查看
            </td>
            <td class="money">{{ item.total_token }}</td>
            <td class="money">-￥{{ item.money }}</td>
            <td>
              {{ item.timer }}
            </td>
            <td class="operation4">
              <p @click="copyCont(item.output_url)">一键复制</p>
              <p @click="deleteFn(index)">删除</p>
            </td>

          </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
    </div>
    <el-dialog title="输入内容/生成内容" :append-to-body="true" :visible.sync="WorkOrder" :show-close="false">
      <div v-if="currentView.type===0">
        <div v-if="currentView.input_style == 1">
          输入内容（文本）：{{ currentView.input_text }}
        </div>
        <div v-if="currentView.input_style == 2">
          输入内容（音频）：{{ currentView.input_voice }}
          <audio controls style="width: 100%" :src="currentView.input_voice"></audio>
        </div>
      </div>
      <div v-if="currentView.type===1" style="display: flex;justify-content: center;align-items: center">
        <video controls style="max-height: 400px;max-width: 100%" :src="currentView.output_url"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import {mapState} from 'vuex'
import {timeCut} from '../../util/timeCut'

export default {
  components: {
    Title,
  },
  computed: {
    ...mapState(['activeTitle'])
  },
  mounted() {
    this.$store.commit('childTab', 'AI记录')
    let searchName_list = ['2D精品数字人', '2D数字人基础版', '2D数字人高级版', '3D数字人']
    searchName_list.forEach((item, index) => {
      this.search_list.push({
        id: index,
        name: item
      })
    })
    console.log(this.search_list);
    this.get_list()

  },
  data() {
    return {
      title: '',
      statusUl: ['全部', '已处理', '未处理',],
      status: {
        id: '',
        title: '全部'
      },
      search_list: [],
      tableData: [],
      total_page: 0, //分页总页数
      page: 1, //当前页数
      limit: 20,
      count: 0,
      fullscreenLoading: false, //loding
      WorkOrder: false,
      contentText: '',
      //   当前查看
      currentView: {}
    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    statusShow(item) {
      if (item == '全部') {
        this.status = {
          id: '',
          title: '全部'
        }

      } else if (item == '未处理') {
        this.status = {
          id: 0,
          title: '未处理'
        }
      } else {
        this.status = {
          id: 1,
          title: '已处理'
        }

      }
      this.get_list()
    },
    wordOrderShow(type, item) {
      this.currentView = item
      this.currentView.type = type
      this.WorkOrder = true
    },
    classChane(index) {
      // console.log(typeof index);
      this.status = this.search_list[index]
      this.get_list()

    },
    copy() {
      //参数是复制的内容
      //.then()中的函数是复制成功和复制失败的两个回调函数
      this.$copyText(this.contentText).then(
          (e) => {
            return this.$message({
              message: '复制成功',
              type: 'success',
            })
          },
          (err) => {
            return this.$message({
              message: '复制失败',
              type: 'warning',
            })
          }
      )

    },
    copyCont(text) {
      //参数是复制的内容
      //.then()中的函数是复制成功和复制失败的两个回调函数
      this.$copyText(text).then(
          (e) => {
            return this.$message({
              message: '复制成功',
              type: 'success',
            })
          },
          (err) => {
            return this.$message({
              message: '复制失败',
              type: 'warning',
            })
          }
      )

    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      if (this.status.id !== '') {
        data.title = String(this.status.id)
      }

      data.mobile = this.$user_info.mobile
      data.page_num = this.page
      data.limit = this.limit
      this.curlPost('/szr/create_history', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.total_page = res.data.totle_page
          this.count = res.data.count
          this.tableData = res.data.data

          if (this.tableData.length != 0) {
            this.tableData.forEach((item, index) => {
              // item.total_token = item.total_token
              this.$set(item, 'timer', timeCut(item.add_time * 1000))
              // this.tableData[index].total_token = this.tableData[index].total_token * 0.0006
              this.$set(item, 'chat_class',)

            })
          }

        }
        this.fullscreenLoading = false
      })
    },
    ClassNum(val) {
      let resultArr = this.gatewayList.filter((item) => {
        return item.title === val
      });
      console.log(resultArr);
      this.resource = resultArr[0]
    },
    deleteFn(index) {
      this.$confirm('确认删除吗?该操作不可撤回', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/szr/delete_order', {
          delete_id: this.tableData[index].id,
          mobile: this.$user_info.mobile
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.tableData.splice(index, 1)
            this.$message({
              type: 'success',
              message: '已删除'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';

::v-deep .el-dialog .el-dialog__body {
  padding: 1rem 2rem 6rem;
  box-sizing: border-box;
  max-height: 60vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: #fff;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #CCCCCC;
  }
}

::v-deep .el-input__icon {
  line-height: 30px;
}
</style>